import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import Image from '../../components/image';
import ImageLandscape from '../../components/sub/landscape';
import PortraitsTwo from '../../components/sub/portraitx2';
export const frontmatter = {
  title: "Inside Louis Vuitton's NYC Pop-Up",
  date: "2019-01-31",
  author: "Rachael Bennet",
  authorTitle: "Creative Director of Doneger Creative Services",
  teaserImage: "../../../static/media/20190131_1.jpg",
  path: "2019-01-31-Inside-Louis-Vuittons-NYC-Pop-Up",
  teaserText: "The new artistic director for Louis Vuitton menswear brought his SS19 line to NYC for a showing of his debut collection for the luxury house. "
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Inside Louis Vuitton's NYC Pop-Up" description="For Breast Cancer Awareness Month, brands and consumers looking to shop it forward can do so via a host of month-long activations and limited-edition merchandise. Building a sense of community while providing real-time impact, these socially conscious and give back models continue to inspire consumers." author='Rachel Bennett' keywords={['Pink', 'Breast', 'Cancer', 'Athleta']} />
      <ImageLandscape imageSrc="20190131_1.jpg" />
      <MDXTag name="p" components={components}>{`The new artistic director for Louis Vuitton menswear brought his SS19 line to NYC for a showing of his debut collection for the luxury house. Hosted by Chrome Hearts in West Village, the pop-up included styles inspired by "The Wizard of Oz." The clothing was an incredible blend of creativity, luxury and oversized streetwear, as is his signature style. While the details were plentiful, it was the experience of being assigned a personal advisor and brand expert to escort shoppers through the collection that made the pop-up so memorable.`}</MDXTag>
      <ImageLandscape imageSrc="20190131_2.jpg" />
      <PortraitsTwo data={[{
        imageSrc: "20190131_3.jpg"
      }, {
        imageSrc: "20190131_4.jpg"
      }]} />
      <MDXTag name="p" components={components}>{`Each brand advisor customized the experience for each individual shopper, and they were there to enhance the experience no matter if the patron was there to snap a selfie, to shop or to be guided through a gallery tour of Virgil's most inconspicuous details. Shoppers left the pop-up feeling educated and having a closer connection to the brand. The takeaway is that the future of luxury retail is a full-on embrace and upgrade of the store associate to something higher, an "experience engineer" for a new era of physical retail.`}</MDXTag>
      <PortraitsTwo data={[{
        imageSrc: "20190131_5.jpg"
      }, {
        imageSrc: "20190131_6.jpg"
      }]} />
           </MDXTag>;
  }

}
export const _frontmatter = {};
    